.shipping-overlay {
	background-color: #00000099;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.shipping-border {
	height: 0.1875rem;
	width: 100%;
	background-position-x: -1.875rem;
	background-size: 7.25rem 0.1875rem;
	background-image: repeating-linear-gradient(
		45deg,
		#6fa6d6,
		#6fa6d6 33px,
		transparent 0,
		transparent 41px,
		#f18d9b 0,
		#f18d9b 74px,
		transparent 0,
		transparent 82px
	);
}
